/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom global styles */
body {
  font-family: "Saira", sans-serif;
  margin: 0;
  padding-top: 150px;
  padding-bottom: 40px;
  background-color: #f3f4f6;
}

.btn {
  background-color: #3498db;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #2980b9;
}
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}
.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Saira", sans-serif;
  font-weight: bold;
}

p, ul, ol {
  font-family: "Saira", sans-serif;
  font-size: 16px;
  line-height: 1.6;
}

.saira-1 {
  font-family: "Saira", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 80;
}